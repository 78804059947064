.card-grid {
}

.card {
  .overlay {
    opacity: 0;
    background-color: hsl(176, 19, 55, 0.95);
    transition: all 0.5s ease;
  }

  .content {
    transition: all 0.5s ease;
    transform: translateY(50px);
  }

  button,
  a {
    opacity: 0;
    transition: all 0.5s ease;
    border-color: #fff;
    color: #fff;
  }

  &:hover {
    .overlay {
      opacity: 1;
    }

    .content {
      transform: translateY(-25px);
    }

    button,
    a {
      opacity: 1;
    }
  }
}

.gradient-overlay {
  pointer-events: none;
}

.gradient-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(
    180deg,
    rgba(7, 7, 7, 0) 0%,
    rgba(0, 0, 0, 0.15) 55%,
    rgba(0, 0, 0, 0.5) 100%
  );
  pointer-events: none;
}
