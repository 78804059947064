@keyframes gradient {
  0% {
    background-position: 100% 0%, 0% 0%;
    background-size: 200% 200%, auto;
  }
  60% {
    background-position: 60% 0%, 0% 0%;
  }
  100% {
    background-position: 40% 0%, 0% 0%;
    background-size: 300% 100%, auto;
  }
}

.c-brand-truth {
  place-content: center;
  background-color: var(--dark-blue);
  color: var(--light-teal);
  aspect-ratio: 1500 / 714;
  font-size: clamp(2rem, 10vw, 5rem);
  background-image: linear-gradient(
      90deg,
      rgba(12, 35, 64, 0) 24%,
      rgba(12, 35, 64, 1) 34%,
      rgba(12, 35, 64, 1) 52%,
      rgba(255, 255, 255, 0) 76%
    ),
    url(/pattern.svg);
  background-repeat: no-repeat, repeat;
  background-size: 200% 200%, auto;
  background-position: 100% 0%, 0% 0%;

  @media screen and (max-width: 767px) {
    min-height: 50vh;
    aspect-ratio: unset;
    padding: 4rem 2rem;
    background-size: 600% 600%, auto;
  }

  &.visible {
    animation: gradient;
    animation-duration: 3s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-play-state: running;
  }

  .text {
    max-width: 1100px;
    line-height: 1.1;
    text-align: center;
    font-family: var(--truth-font-family);
    font-weight: 400;
    font-size: 100px;

    @media screen and (max-width: 767px) {
      font-size: 4.5rem;
    }
  }
}
