.card-block {
}

.card {
  ul {
    list-style: disc;
    padding-left: 1.5rem;
  }

  b,
  strong {
    font-weight: 600;
  }
}
