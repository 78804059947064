.community-map {
  .marker {
    .text {
      transition-delay: 300ms;
    }
  }
  .marker:has(svg:hover) {
    .text {
      transition-delay: 300ms;
      opacity: 1;
    }
  }
}
