.misson-and-values {
  //height: 100vh;
  background-image: url(https://images.ctfassets.net/qmuogfuv095q/78RVEvd3AiZoCSabPcwATS/2561e3a76bf3869e8dd2ba9b7c2023a7/iStock-879005182.jpg);
  // overflow: scroll;
  // position: sticky;
  background-color: var(--dark-blue);
  background-size: cover;
  background-position: 36%;
  background-attachment: fixed;
  padding-bottom: 10vh;

  @media screen and (max-width: 767px) {
    // overflow: auto;
    // height: auto;
    padding: 5rem 0;
    background-position: 56%;
    // background-image: none;
  }

  .title {
    padding: 0 var(--gutter);
    h2 {
      color: var(--dark-blue);
      font-size: clamp(4rem, 10vw, 7rem);
    }

    :global(.c-mark) {
      width: 50px;
    }
  }

  .cards {
    padding: 0 var(--gutter);
    gap: 155px;

    @media screen and (max-width: 767px) {
      gap: 60px;
    }
  }
  .card {
    padding: 2.5rem;
    background-color: var(--light-teal);
    width: 600px;
    max-width: 100%;
    min-height: 320px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: var(--dark-blue);

    @media screen and (max-width: 767px) {
      :global(h3) {
        font-size: 2rem;
        margin-bottom: 2rem;
      }
    }
  }

  .mission,
  .values,
  .vision {
    //position: absolute;
    //top: calc(50% - 160px);
    //transform: translateY(40vh);
    margin-top: calc(50% - 160px);
    opacity: 1;

    @media screen and (max-width: 767px) {
      position: relative;
      top: unset;
      transform: translateY(0);
    }
  }
}
