.two-column {
  ul {
    list-style: disc;
    padding-left: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  b,
  strong {
    font-weight: 600;
  }
}
